<template lang="pug">
.dashboard-courier
  h2 Entregadores
  .font-weight-light.pb-4 Entregas por períodos
  v-data-table( 
    fixed-header
    :headers="headers"
    :items="couriers.data"
    class="elevation-1"
    :loading='couriers.data.length <= 0'
    loading-text="Carregando... Aguarde!"
    hide-default-footer
    )
  
  </template>
  
  <script>
  export default {
    name: "DashboardCourier",
    data: function() {
      return {
        headers: [
          {   text: 'Entregador', value: 'fullname', sortable: false, },
          {   text: '7 dias', value: 'orders_count_7', align: 'center', sortable: false, width: '10%', },
          {   text: '15 dias', value: 'orders_count_15', align: 'center', sortable: false, width: '10%', },
          {   text: '30 dias', value: 'orders_count_30', align: 'center', sortable: false, width: '10%', },
          {   text: '60 dias', value: 'orders_count_60', align: 'center', sortable: false, width: '10%', },
          {   text: '90 dias', value: 'orders_count_90', align: 'center', sortable: false, width: '10%', },          
        ],
        couriers: {
          data: []
        }
      }
    },

    created: function (){
      this.loadData();
    },
    methods: {
      loadData: async function(){
          let couriers = await this.api.get( 'couriers/dashboard')
          this.couriers.data = couriers.data 
      },
    },
  };
  </script>
  