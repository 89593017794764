<template lang="pug">
.dashboard
  v-container.pa-5(fluid)
    v-row
      v-col(md='6')
        CourierDashboard
</template>

<script>

import CourierDashboard from '@/views/Couriers/Widget/Dashboard';

export default {
  name: "Dashboard",
  components: {
    CourierDashboard
  }
};
</script>
